@import '../sizing.scss';
@import '../variables.scss';

.c-ion-modal {
  --box-shadow: none;
  align-items: center;

  &::part(backdrop) {
    display: block;
    --backdrop-opacity: 0.3;
  }

  &::part(content) {
    --width: 100%;
    --height: 100%;
    --background: transparent !important;
    z-index: 101;
  }

  &.modal-shadow {
    max-width: $max-sm;
    min-height: 440px;
    margin: auto auto max(#{$size-sm}, calc(#{$size-sm} + constant(safe-area-inset-bottom))); // for ios 11.1
    margin: auto auto max(#{$size-sm}, calc(#{$size-sm} + env(safe-area-inset-bottom)));
  }

  &__date {
    .ion-page {
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.c-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 1rem);
  max-width: $max-sm;
  background: white;
  margin: auto auto max(#{$size-sm}, calc(#{$size-sm} + constant(safe-area-inset-bottom))); // for ios 11.1
  margin: auto auto max(#{$size-sm}, calc(#{$size-sm} + env(safe-area-inset-bottom)));
  padding: $size-md;
  border-radius: #{1.5 * $size-base};

  &__ion-content {
    --background: var(--light-gray);
  }

  &__backdrop {
    position: absolute;
    top: -150px; // include an offset to make the overlay not look weird
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    cursor: pointer;
  }

  &--center {
    align-items: center;
  }

  &--input {
    padding: 0 !important;

    @media screen and (min-width: $break-md) {
      max-width: unset;
      min-height: unset;
      width: fit-content;
      height: fit-content;
    }
  }

  &--short {
    min-height: unset;

    @media screen and (min-width: $break-md) {
      max-width: unset;
      min-height: unset;
      width: fit-content;
      height: fit-content;
    }
  }

  &__wrapper {
    display: contents;
  }

  &__close {
    --color: var(--color-black);
    position: absolute;
    top: $size-md;
    right: $size-md;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size-lg;
    height: $size-lg;
    background: var(--color-gray-200);
    border-radius: 50%;
    padding: 0;
    border: 0;
    cursor: pointer;
    z-index: 999;

    &:before {
      content: '';
      position: absolute;
      width: 14px;
      height: 2px;
      background: var(--color);
      transform: rotate(45deg);
      transform-origin: center;
    }

    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 2px;
      background: var(--color);
      transform: rotate(-45deg);
      transform-origin: center;
    }

    &:focus {
      outline: 0;
    }

    &:hover {
      --color: var(--color-primary);
    }
  }

  &__back {
    position: absolute;
    top: $size-md;
    left: $size-md;
    width: $size-lg;
    height: $size-lg;
    border: 0;
    color: var(--color-blue-500);
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:hover {
      color: var(--color-primary-500);
    }

    ion-button {
      // reset ionic
      --padding-start: 0;
      --padding-end: 0;
      --color: var(--color-blue-500);
    }
  }

  app-back-button.c-modal__back {
    top: $size-sm;
  }

  &__illustration {
    margin-top: -#{$size-md};
    margin-bottom: $size-lg;
    overflow: hidden;
  }

  &__icon {
    margin-top: $size-md;
    margin-bottom: 1.5 * $size-base;
  }

  &__title {
    margin-bottom: $size-md;
    text-align: center;
    line-height: 1.25em;
  }

  &__description {
    width: 100%;
    color: var(--color-black);
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 14 * $size-base;
  }

  &__footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: $size-lg;
    margin-top: auto;

    &--vertical {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 1rem;
      width: 100%;
      height: 1px;
      background: var(--color-gray-400);
    }
  }
}

@media (min-width: $break-sm) {
  .c-modal {
    padding: $size-lg;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 1rem;

    &__icon {
      display: none !important;
    }

    &__icon--show-sm {
      display: block !important;
    }

    &__title {
      margin-top: $size-md;
      font-weight: 900;
    }
  }
}

@media (max-width: $break-sm) {
  .c-ion-modal {
    &--full-height {
      min-height: calc(100vh - 8rem);
    }

    ::part(content) {
      padding-left: $size-sm;
      padding-right: $size-sm;
      padding-bottom: max(
        #{$size-sm},
        calc(#{$size-sm} + constant(safe-area-inset-bottom))
      ); // for ios 11.1
      padding-bottom: max(
        #{$size-sm},
        calc(#{$size-sm} + env(safe-area-inset-bottom))
      ); // for ios 11.2 onwards
      align-items: flex-end;
    }
  }

  .c-modal {
    margin-top: $size-xl * 2;
    margin-left: $size-sm;
    margin-right: $size-sm;

    &__title {
      font-size: 1.4rem;
    }

    &__footer {
      width: 100%;
      justify-content: center;

      &:before {
        display: none;
      }
    }
  }
}

@media (max-width: $break-xs) {
  .c-ion-modal {
    &--full-height {
      .c-modal {
        min-height: calc(100vh - 3rem);
      }
    }
  }

  .c-modal {
    margin-top: 3 * $size-base;

    &__icon {
      margin-top: 0;
    }
  }
}
